import dayjs from "dayjs";
import VConsole from 'vconsole'
import {
	inDevelop
} from '@/config'


export const copy = o => JSON.parse(JSON.stringify(o))
export const inMobile = /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)

export function debounce(fn, wait = 500) {
	var timer = null;
	return function() {
		timer && clearTimeout(timer);
		timer = setTimeout(fn, wait);
	}
}

export function throttle(func, delay = 500) {
	var prev = Date.now();
	return function() {
		var context = this;
		var args = arguments;
		var now = Date.now();
		if (now - prev >= delay) {
			func.apply(context, args);
			prev = Date.now();
		}
	}
}

export function dateFormat(timestamp, formatter) {
	return /^\d+$/.test(timestamp) ? dayjs(timestamp * 1000).format(formatter) : timestamp;
}

const noop = () => {}
export function nConsole() {
	const _console_info = window.console.info
	if (location.href.indexOf('debug=xizi') > -1) {
		window.console['info'] = function(...args) {
			const o = copy(args)
			_console_info(...o)
		}
		if (inMobile) {
			new VConsole()
		}
	} else {
		window.console['info'] = noop
	}
}


export const parseQuery = (query) => {
	return Object.keys(query).filter(v => {
		return v != 'size' && !!query[v]
	}).map(v => {
		return `${v}=${query[v]}`
	}).join('&')
}


export function numberFormat(value) {
	return (value || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
}

const loaded = []
export function loadjs(src) {
	return new Promise((resolve, reject) => {
		if (loaded.includes(src)) {
			resolve()
		} else {
			const el = document.createElement('script')
			el.onload = () => {
				loaded.push(src)
				resolve()
			}
			el.onerror = () => {
				reject()
			}
			el.type = 'text/javascript'
			el.src = src
			document.querySelector('head').appendChild(el)
		}
	})
}
export function loadcss(href) {
	return new Promise((resolve, reject) => {
		if (loaded.includes(href)) {
			resolve()
		} else {
			const el = document.createElement('link')
			el.onload = () => {
				loaded.push(href)
				resolve()
			}
			el.onerror = () => {
				reject()
			}
			el.type = 'text/css'
			el.rel = 'stylesheet'
			el.href = href
			document.querySelector('head').appendChild(el)
		}
	})
}
